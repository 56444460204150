/**
 * @generated SignedSource<<9668ede4ec1155a168be6afde5e2e893>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AiProEligibilityStatus = "AI_PRO_ELIGIBILITY_STATUS_ELIGIBLE" | "AI_PRO_ELIGIBILITY_STATUS_ENROLLED" | "AI_PRO_ELIGIBILITY_STATUS_INELIGIBLE" | "AI_PRO_ELIGIBILITY_STATUS_PENDING" | "AI_PRO_ELIGIBILITY_STATUS_UNKNOWN" | "%future added value";
export type AIProTrialSignUp_Query$variables = {
  companyId: string;
};
export type AIProTrialSignUp_Query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AIProTrialSignUpCard_QueryFragment">;
};
export type AIProTrialSignUp_Query$rawResponse = {
  readonly aiProTrialEligibilityStatus: {
    readonly eligibilityStatus: AiProEligibilityStatus;
    readonly id: string;
    readonly reason: {
      readonly reason: {
        readonly __typename: string;
      } | null;
    } | null;
  } | null;
};
export type AIProTrialSignUp_Query = {
  rawResponse: AIProTrialSignUp_Query$rawResponse;
  response: AIProTrialSignUp_Query$data;
  variables: AIProTrialSignUp_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "companyId",
    "variableName": "companyId"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AIProTrialSignUp_Query",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "AIProTrialSignUpCard_QueryFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AIProTrialSignUp_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "GetAiProTrialEligibilityStatusPayload",
        "kind": "LinkedField",
        "name": "aiProTrialEligibilityStatus",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "eligibilityStatus",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AiProIneligibleReason",
            "kind": "LinkedField",
            "name": "reason",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "reason",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0c73f098efef13fbd376920604acbb55",
    "id": null,
    "metadata": {},
    "name": "AIProTrialSignUp_Query",
    "operationKind": "query",
    "text": "query AIProTrialSignUp_Query(\n  $companyId: ID!\n) {\n  ...AIProTrialSignUpCard_QueryFragment_MWiJC\n}\n\nfragment AIProTrialSignUpCard_QueryFragment_MWiJC on Query {\n  aiProTrialEligibilityStatus(companyId: $companyId) {\n    eligibilityStatus\n    id\n    reason {\n      reason {\n        __typename\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "aa9662805c68ffbbd8c712d6ef90b1b5";

export default node;
